export default [
  {
    title: 'Sessions',
    route: 'startup-sessions',
    icon: 'ClockIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Requests',
    route: 'startup-requests',
    icon: 'InboxIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Partner Services',
    route: 'startup-partner-services',
    icon: 'LinkIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Library',
    route: 'startup-library-module-details',
    icon: 'FolderIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Forms',
    // @TODO: Update route
    route: 'startup-portal-forms',
    icon: 'ClipboardIcon',
    resource: 'User',
    action: 'read',
  },
  {
    header: 'Opportunities',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Startup Programs',
    route: 'startup-portal-program',
    icon: 'TrendingUpIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Events',
    route: 'startup-portal-events',
    icon: 'CalendarIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Competitions',
    route: 'startup-portal-competition',
    icon: 'AwardIcon',
    resource: 'User',
    action: 'read',
  },
  {
    header: 'Performance',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Business Details',
    route: 'startup-portal-business-details',
    icon: 'BriefcaseIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Milestones',
    route: 'startup-portal-milestones',
    icon: 'FlagIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Growth',
    route: 'startup-portal-growth',
    icon: 'TrendingUpIcon',
    resource: 'User',
    action: 'read',
  },
  {
    header: 'Ecosystem',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Mentors',
    route: 'startup-mentors',
    icon: 'UserPlusIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Incubator Manager',
    route: 'incubator-manager',
    icon: 'LayersIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Startup Manager',
    route: 'startup-manager',
    icon: 'BarChart2Icon',
    resource: 'User',
    action: 'read',
  },

]
