<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div
      class="navbar-header expanded"
      style="height: auto !important;"
    >
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="align-items-center d-flex flex-nowrap flex-row nav navbar-nav mt-1">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span
                class="brand-logo"
              >
                <Promised
                  v-if="incubator.logo"
                  v-once
                  :promise="getLinkFromS3(incubator.logo)"
                >
                  <template #pending>
                    <b-spinner />
                  </template>
                  <template #default="data">
                    <b-img
                      :src="data"
                      fluid
                    />
                  </template>
                  <template #rejected>
                    <span>
                      No image uploaded.
                    </span>
                  </template>
                </Promised>
              </span>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <!--          <li class="nav-item nav-toggle">-->
          <!--            <b-link class="nav-link modern-nav-toggle">-->
          <!--              <feather-icon-->
          <!--                icon="XIcon"-->
          <!--                size="20"-->
          <!--                class="d-block d-xl-none"-->
          <!--                @click="toggleVerticalMenuActive"-->
          <!--              />-->
          <!--              <feather-icon-->
          <!--                :icon="collapseTogglerIconFeather"-->
          <!--                size="20"-->
          <!--                class="d-none d-xl-block collapse-toggle-icon"-->
          <!--                @click="toggleCollapsed"-->
          <!--              />-->
          <!--            </b-link>-->
          <!--          </li>-->
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowTop}"
      class="shadow-top"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowTop = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <!-- Shadow -->
    <div
      class="shadow-bottom"
    />

  </div>
</template>

<script>
import gql from 'graphql-tag'
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, BSpinner } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { Promised } from 'vue-promised'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    BSpinner,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    Promised,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowTop = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'SquareIcon' : 'SidebarIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowTop,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      logourl: '',
    }
  },
  data() {
    return {
      incubator: '',
      locallogo: 1,
    }
  },
  apollo: {
    incubator: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks' || urlPrefix.toLowerCase() === 'test' || urlPrefix.toLowerCase() === 'demo') ? '.*' : urlPrefix
        return gql`
          {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}}) {
              logo
            }
          }`
      },
      update(data) {
        if (data.users_organizationtable.length > 0) {
          return data.users_organizationtable[0]
        }
        return ''
      },
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
