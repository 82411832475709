export default [
  {
    title: 'Incubator Manager',
    route: 'manage-incubator',
    icon: 'LayersIcon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Sessions',
    route: 'sessions-manager',
    icon: 'ClockIcon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Requests',
    route: 'request-details',
    icon: 'InboxIcon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Partner Services',
    route: 'partner-services-details',
    icon: 'LinkIcon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Library',
    route: 'library-module-details',
    icon: 'FolderIcon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Forms',
    route: 'forms-module-details',
    icon: 'ClipboardIcon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    header: 'Opportunities',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Startup Programs',
    route: 'manage-programs',
    icon: 'TrendingUpIcon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Events',
    route: 'event-details',
    icon: 'CalendarIcon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Competitions',
    route: 'manage-competitions',
    icon: 'AwardIcon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    header: 'Ecosystem',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Startups',
    route: 'manage-startups',
    icon: 'BarChart2Icon',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Mentors',
    route: 'manage-mentors',
    icon: 'UserPlusIcon',
    resource: 'Incubator',
    action: 'read',
  },
]
