import startupPortal from './startup-portal'
import mentorPortal from './mentor-portal'
import incubatorPortal from './incubator-portal'
import common from './common'

export default [
  ...common,
  ...startupPortal,
  ...mentorPortal,
  ...incubatorPortal,
]
