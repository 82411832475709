export default [
  {
    title: 'Invitations',
    route: 'mentor-invitations',
    icon: 'UserPlusIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Sessions',
    // @TODO: Update route
    route: 'mentor-sessions',
    icon: 'ClockIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Requests',
    route: 'request-details-mentor',
    icon: 'InboxIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Library',
    route: 'mentor-library-module-details',
    icon: 'FolderIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    header: 'Opportunities',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Startup Programs',
    route: 'mentor-programs',
    icon: 'TrendingUpIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Events',
    route: 'mentor-events',
    icon: 'CalendarIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Competitions',
    route: 'mentor-competitions',
    icon: 'AwardIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    header: 'Manage Ecosystem',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Incubator Manager',
    route: 'mentor-incubators',
    icon: 'LayersIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Startups',
    route: 'mentor-startups',
    icon: 'BarChart2Icon',
    resource: 'Mentor',
    action: 'read',
  },
]
